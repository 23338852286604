import React, { useState } from "react"
import Layout from "../components/layout"
import Modal from "../pages/modal"

import Toppturbilde from "../img/topptur2.jpg"
import Basecamp from "../img/basecamp.png"
import Toppturbilde2 from "../img/topptur.jpg"
import Kveldstur from "../img/kveldstur.jpg"

export default function Topptur() {
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState("")
  return (
    <div>
      <Modal show={show} onClose={() => setShow(false)} title={title} />
      <Layout>
        <div>
          <img
            className="h-80 w-full object-cover object-bottom rounded-lg shadow-lg mt-6"
            src={Toppturbilde}
            alt="Terrengsykkel Norco Fluid HT 2 på Håheimsfjellet"
          />
        </div>
        <h1 className="text-3xl text-yellow-700 text-center mt-12 font-bold ">
          Topptur
        </h1>

        <div className="grid md:grid-cols-3 mt-12 gap-12 md:gap-3 mb-12">
          {/* Guidet topptur */}
          <div className="border shadow-lg rounded flex flex-col justify-between">
            <img
              src={Toppturbilde2}
              className="object-cover object-top h-40 w-full rounded-t"
              alt="Guidet topptur i Jølster i Sunnfjord"
            />
            <div className="p-2 flex flex-col justify-between">
              <h2 className="text-center text-blue-700 lg:text-2xl text-xl font-bold">
                Guidet topptur
              </h2>
              <p>
                Vi kan ta dokker med på guidet topptur, enten på dagen eller på
                kvelden med hodelykt. Dette kan eventuelt kombinerast med
                basecamp. Sjå eigen artikkel for dette. Ved bestilling vert vi einig om kor vi går topptur. 
              </p>
              <p className="mt-1.5">
                Prisar kr. 850,- per person. Minstepris kr. 2000,- per gruppe.
              </p>
            </div>
            <button
              onClick={() => {
                setTitle("bestilling av guidet topptur")
                setShow(true)
              }}
              class="mb-3 block mx-2 text-green-50 px-3 py-1 rounded-md bg-blue-800 hover:bg-blue-700 mt-6"
            >
              Bestilling
            </button>
          </div>
          {/* Topptur med bacecamp */}
          <div className="shadow-lg rounded border-gray-50 flex flex-col justify-between">
            <div>
              <img
                src={Basecamp}
                className="object-cover h-40 w-full rounded-t"
                alt="Topptur med basecamp inklusiv mat og drikke på Jølster i Sogn og Fjordane"
              />
              <div className="p-2 ">
                <h2 className="text-center text-purple-800 lg:text-2xl text-xl font-bold">
                  Topptur med basecamp
                </h2>
                <p>
                  Vi tilbyr guidet topptur i fjella i Jølster. Toppturen kan
                  enten vere på dagen eller på kvelden som hodelykttur. Etterpå
                  vert det basecamp der vi har sett opp lavvo og fyrer i
                  bålpanne. Når du tek kontakt vert vi einig om kor toppturen går og meny. 
                </p>
                <p className="mt-1.5">
                  Pris frå kr. 900,- per person inklusiv varm mat og drikke.
                  Minstepris kr. 2500,- per gruppe.
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                setTitle("bestilling av topptur med basecamp")
                setShow(true)
              }}
              class="mb-3 block mx-2 text-green-50 px-3 py-1 rounded-md bg-purple-800 hover:bg-purple-700  mt-6"
            >
              Bestilling
            </button>
          </div>
          {/* Kveldstur */}
          <div className="border shadow-lg rounded flex flex-col justify-between">
            <img
              src={Kveldstur}
              className="object-cover h-40 w-full rounded-t"
              alt="Guidet kveldstur med hodelykt i Jølster i Vestland"
            />
            <div className="p-2 flex flex-col justify-between">
              <h2 className="text-center text-gray-700 lg:text-2xl text-xl font-bold">
                Kveldstur med hodelykt
              </h2>
              <p>
                Kveldstur med hodelykt kan vere ein fantastisk oppleving. Vi har
                mange av dei fine turane om vinteren på kvelden med hodelykt.
              </p>
              <p className="mt-1.5"> 
                Dette er heilt klart å foretrekke før turar på overskya dager
                med flatt lys.
              </p>
              <p className="mt-1.5">
                {" "}
                Prisar kr. 700,- per person. Minstepris kr. 1500,- per gruppe.
              </p>
            </div>{" "}
            <button
              onClick={() => {
                setTitle("bestilling av kveldstur")
                setShow(true)
              }}
              class="mb-3 block mx-2 text-green-50 px-3 py-1 rounded-md bg-gray-800 hover:bg-gray-700  mt-6"
            >
              Bestilling
            </button>
          </div>
        </div>
      </Layout>
    </div>
  )
}
